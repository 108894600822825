const INFO = {
	main: {
		title: "Portfolio of Boljo",
		name: "Boljo ",
		email: "mail@Boljo.dev",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/",
		linkedin: "https://linkedin.com/",
		instagram: "https://instagram.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Salesforce Developer/Administrator and Tech Enthusiast.",
		description:
			"I am a Salesforce developer and administrator with expertise in building scalable, secure, and efficient Salesforce solutions. I enjoy solving complex business challenges, automating processes, and improving customer experiences. I am passionate about creating high-quality, reliable solutions that follow industry standards. Always eager to learn and grow, I’m open to new opportunities and challenges in the tech world.",
	},

	about: {
		title: "I’m Boljo, living in Germany, where I craft smart solutions.",
		description:
			"Over the years, I’ve contributed to various Salesforce implementations and projects that have significantly impacted business operations. Many of these initiatives have helped businesses streamline their processes. I take pride in the work I've done, and I am constantly looking for opportunities to enhance the solutions I develop. If you're interested in my work, feel free to connect, and let's discuss potential collaborations. I thrive in collaborative environments where knowledge sharing and growth are encouraged.",
	},

	articles: {
		title: "I'm driven by innovation and dedicated to improving business solutions through Salesforce.",
		description:
			"A chronological collection of my in-depth thoughts on Salesforce development, automation, and how technology can reshape business processes.",
	},

	projects: {
		title: "Salesforce solutions I’ve built to help improve business processes.",
		description:
			"Over the years, I’ve worked on various Salesforce projects aimed at optimizing workflows and enhancing customer experiences. I'm proud of the progress I've made, and many of these solutions are impactful in streamlining business operations. If you're interested in discussing or exploring the work I've done, feel free to reach out. I believe that collaboration is a great way to grow and improve, and I’m always open to new ideas and feedback.",
	},

};

export default INFO;
